export enum EventAuditEnum {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
  RESTORED = 'restored',
}

export enum ToolRoadTypeEnum {
  tskad = 'tskad',
  m12 = 'm12',
  sdkp = 'sdkp',
  msd = 'msd',
  soo = 'soo',
}
