import { GridColDef } from '@mui/x-data-grid'
import { toolRoadTypeEnumLabels } from '~/shared/config/constants'
import { priceFormat } from '~/shared/lib/utils'
import { ColumnDate, ColumnValue } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Дата и время проезда',
    field: 'eventDatetime',
    minWidth: 184,
    renderCell: ({ value }) => <ColumnDate type='dateTime' value={value} />,
  },
  {
    headerName: 'Номер',
    field: 'documentNumber',
    minWidth: 156,
  },
  {
    headerName: 'Тип проезда',
    field: 'roadType',
    minWidth: 156,
    renderCell: ({ row }) => (
      <ColumnValue>{toolRoadTypeEnumLabels[row.roadType]}</ColumnValue>
    ),
  },
  {
    headerName: 'Автомобиль (гос.номер)',
    field: 'carPlateNumber',
    minWidth: 190,
  },
  {
    headerName: 'СТС',
    field: 'carSts',
    minWidth: 140,
  },
  {
    headerName: 'Организация',
    field: 'paymentOrgName',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Подразделение авто',
    field: 'tmsSubdivisionName',
    minWidth: 170,
  },
  {
    headerName: 'Водитель',
    field: 'tmsDriverName',
    minWidth: 240,
  },
  {
    headerName: 'Точка контроля взимания платы',
    field: 'eventLocation',
    minWidth: 240,
  },
  {
    headerName: 'Сумма к оплате',
    field: 'paymentAmount',
    renderCell: ({ value }) => value && priceFormat(value),
    minWidth: 140,
  },
  {
    headerName: 'Оплачено',
    field: 'paymentIsPaid',
    renderCell: ({ value }) => (
      <ChipStatus
        variant={value ? 'green' : 'red'}
        label={value ? 'Да' : 'Нет'}
      />
    ),
    minWidth: 108,
  },
  {
    headerName: 'Дата загрузки в систему',
    field: 'createdAt',
    renderCell: ({ value }) => <ColumnDate type='date' value={value} />,
    minWidth: 200,
  },
  {
    headerName: 'Дата обновления',
    field: 'updatedAt',
    renderCell: ({ value }) => <ColumnDate type='date' value={value} />,
    minWidth: 200,
  },
  {
    headerName: 'Комментарий',
    field: 'comment',
    minWidth: 140,
  },
]
