import * as z from 'zod'
import { ToolRoadTypeEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  documentDate: z.string(),
  documentNumber: z.string(),
  odpsCode: z.string(),
  odpsName: z.string(),
  odpsAddr: z.string(),
  carExtId: z.string(),
  carPlateNumber: z.string(),
  carVin: z.string(),
  carBrandTitle: z.string(),
  carModelTitle: z.string(),
  carSts: z.string(),
  carMetaData: z.string(),
  paymentOrgInn: z.number(),
  paymentOrgName: z.number(),
  paymentUin: z.number(),
  paymentType: z.string(),
  paymentPayIdentifier: z.string(),
  paymentAmount: z.number(),
  paymentDiscountDate: z.string(),
  paymentUntilDate: z.string(),
  paymentIsPaid: z.boolean(),
  paymentAdditionalInfo: z.string(),
  paymentMetaData: z.string(),
  recipient: z.string(),
  recipientInn: z.number(),
  recipientKpp: z.number(),
  recipientBankAccountNumber: z.number(),
  recipientBankName: z.string(),
  recipientBankBik: z.number(),
  recipientBankPurpose: z.string(),
  recipientBankUsername: z.string(),
  recipientBankKbk: z.number(),
  recipientBankOktmo: z.number(),
  recipientBankSign: z.string(),
  recipientBankBilldate: z.string(),
  tmsSubdivision: z.string(),
  tmsSubdivisionName: z.string(),
  driverExtId: z.string(),
  tmsDriver: z.string(),
  tmsDriverName: z.string(),
  comment: z.string(),
  bankclientExported: z.boolean(),
  bankclientLastExportAmount: z.number(),
  bankclientLastExportDate: z.string(),
  roadName: z.string(),
  roadType: z.nativeEnum(ToolRoadTypeEnum),
  files: z.array(
    z.object({
      uuid: z.string(),
      previewUrl: z.string(),
      originalUrl: z.string(),
    }),
  ),
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema,
  })
  .merge(attributesSchema)

export type TollRoadAttributes = z.infer<typeof schema>
export type TollRoadExportBankclient = {
  startNumber: string
  companyBankDetailId: UniqueId
  tollRoadsIds: string[]
}

export class TollRoad extends ApiModel<typeof schema, TollRoadAttributes> {
  static jsonApiType = 'toll-roads'

  static schema = schema

  getRoadName(): string {
    return this.getAttribute('roadName')
  }

  getEventDatetime(): string {
    return this.getAttribute('eventDatetime')
  }

  getCreatedAt(): string {
    return this.getAttribute('createdAt')
  }

  getDocumentNumber(): string {
    return this.getAttribute('documentNumber')
  }

  getDocumentDate(): string {
    return this.getAttribute('documentDate')
  }

  getRoadType(): ToolRoadTypeEnum {
    return this.getAttribute('roadType')
  }

  static async exportBankclientTXT({
    startNumber,
    companyBankDetailId,
    tollRoadsIds,
  }: TollRoadExportBankclient) {
    const url = `${this.getJsonApiUrl()}/actions/export-bankclient-txt`
    const client = TollRoad.httpClient.getImplementingClient()
    const res = await client.post(
      url,
      {
        startNumber,
        companyBankDetailId,
        additionalFilterById: tollRoadsIds,
      },
      {
        responseType: 'blob',
      },
    )
    return res.data
  }

  static async exportBankclientEXCEL({
    startNumber,
    companyBankDetailId,
    tollRoadsIds,
  }: TollRoadExportBankclient) {
    const url = `${this.getJsonApiUrl()}/actions/export-bankclient-excel`
    const client = TollRoad.httpClient.getImplementingClient()
    const res = await client.post(
      url,
      {
        startNumber,
        companyBankDetailId,
        additionalFilterById: tollRoadsIds,
      },
      {
        responseType: 'blob',
      },
    )
    return res.data
  }

  getBrandModel(): string {
    const brand = this.getAttribute('carBrandTitle')
    const model = this.getAttribute('carModelTitle')

    if (brand && model) return `${brand} ${model}`
    if (brand) return brand
    if (model) return model

    return ''
  }

  static async getIdList(filters: Record<string, string>): Promise<string[]> {
    const url = `${TollRoad.getJsonApiUrl()}/actions/get-id-list`
    const client = TollRoad.httpClient.getImplementingClient()

    const res = await client.get<{ idList: number[] }>(url, {
      params: filters,
    })
    return res.data?.idList.map(String)
  }

  static async getAmountSum(tollRoadsIds: string[]): Promise<number> {
    const url = `${TollRoad.getJsonApiUrl()}/actions/get-amount-sum`
    const client = TollRoad.httpClient.getImplementingClient()

    const res = await client.post<{ paymentAmountSum: number | undefined }>(
      url,
      {
        additionalFilterById: tollRoadsIds,
      },
    )
    return res.data?.paymentAmountSum || 0
  }

  static async export(filters: string): Promise<Blob> {
    const url = `${TollRoad.getJsonApiUrl()}/actions/export-excel?${filters}`
    const client = TollRoad.httpClient.getImplementingClient()

    const response = await client.post(url, undefined, {
      responseType: 'blob',
    })

    return response?.data
  }
}
