import { GridColDef } from '@mui/x-data-grid'
import { TMS_DOMAIN } from '~/shared/config/env'
import { priceFormat } from '~/shared/lib/utils'
import { ColumnDate, ColumnLink } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Дата протокола',
    field: 'protocolDate',
    minWidth: 146,
    renderCell: ({ row, value }) => (
      <ColumnLink to={row.id}>
        <ColumnDate type='date' value={value} />
      </ColumnLink>
    ),
  },
  {
    headerName: 'Номер протокола',
    field: 'protocolNumber',
    minWidth: 210,
  },
  {
    headerName: 'Автомобиль (гос.номер)',
    field: 'carPlateNumber',
    minWidth: 190,
    renderCell: ({ row, value }) =>
      row?.carExtId ? (
        <ColumnLink href={`${TMS_DOMAIN}cars/${row.carExtId}/details`}>
          {value}
        </ColumnLink>
      ) : (
        value
      ),
  },
  {
    headerName: 'Автомобиль (VIN)',
    field: 'carVin',
    minWidth: 200,
  },
  {
    headerName: 'Автомобиль (марка модель)',
    field: 'carBrandModel',
    minWidth: 210,
  },
  {
    headerName: 'Подразделение авто',
    field: 'tmsSubdivisionName',
    minWidth: 190,
  },
  {
    headerName: 'СТС',
    field: 'carSts',
    minWidth: 160,
  },
  {
    headerName: 'Организация',
    field: 'paymentOrgName',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Сумма к оплате',
    field: 'paymentAmount',
    renderCell: ({ value }) => value && priceFormat(value),
    minWidth: 140,
  },
  {
    headerName: 'Сумма без учета скидки',
    field: 'paymentAmountTotal',
    renderCell: ({ value }) => value && priceFormat(value),
    minWidth: 190,
  },
  {
    headerName: 'Возможность оплаты 50% (со скидкой)',
    field: 'paymentDiscountSize',
    minWidth: 290,
    renderCell: ({ value }) => (
      <ChipStatus
        variant={value ? 'green' : 'red'}
        label={value ? 'Да' : 'Нет'}
      />
    ),
  },
  {
    headerName: 'Дней до окончания скидки',
    field: 'dayUntilDiscountEnd',
    sortable: false,
    minWidth: 210,
  },
  {
    headerName: 'Дней до передачи ФССП',
    field: 'dayUntilPayEnd',
    sortable: false,
    minWidth: 200,
  },
  {
    headerName: 'Платеж выгружен',
    field: 'bankclientExported',
    minWidth: 160,
    renderCell: ({ value }) => (
      <ChipStatus
        variant={value ? 'green' : 'red'}
        label={value ? 'Да' : 'Нет'}
      />
    ),
  },
  {
    headerName: 'Дата ПП (дата выгрузки платежа)',
    field: 'bankclientLastExportDate',
    minWidth: 250,
    renderCell: ({ value }) => <ColumnDate type='date' value={value} />,
  },
  {
    headerName: 'Сумма ПП (сумма платежа)',
    field: 'bankclientLastExportAmount',
    renderCell: ({ value }) => value && priceFormat(value),
    minWidth: 210,
  },
  {
    headerName: 'Оплачено',
    field: 'paymentIsPaid',
    minWidth: 108,
    renderCell: ({ value }) => (
      <ChipStatus
        variant={value ? 'green' : 'red'}
        label={value ? 'Да' : 'Нет'}
      />
    ),
  },
  {
    headerName: 'Дата нарушения',
    field: 'penaltyDatetime',
    minWidth: 150,
    renderCell: ({ value }) => <ColumnDate type='date' value={value} />,
  },
  {
    headerName: 'Время нарушения',
    field: 'penaltyTime',
    sortable: false,
    minWidth: 200,
    renderCell: ({ value }) => <ColumnDate type='time' value={value} />,
  },
  {
    headerName: 'Статья',
    field: 'penaltyLawArticleDescription',
    minWidth: 100,
  },
  {
    headerName: 'Код статьи',
    field: 'penaltyLawArticleCode',
    minWidth: 120,
  },
  {
    headerName: 'Место нарушения',
    field: 'penaltyLocation',
    minWidth: 160,
  },
  {
    headerName: 'Дата и время загрузки',
    field: 'createdAt',
    minWidth: 200,
    renderCell: ({ value }) => <ColumnDate type='dateTime' value={value} />,
  },
  {
    headerName: 'Водитель',
    field: 'tmsDriverName',
    minWidth: 200,
    renderCell: ({ row, value }) =>
      row?.driverExtId ? (
        <ColumnLink href={`${TMS_DOMAIN}drivers/${row.driverExtId}/details`}>
          {value}
        </ColumnLink>
      ) : (
        value
      ),
  },
  {
    headerName: 'Комментарий',
    field: 'comment',
    minWidth: 140,
  },
]
